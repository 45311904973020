@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {

    /* Colors: */
    --unnamed-color-707070: #707070;
    --unnamed-color-f4f4f6: #F4F4F6;
    --unnamed-color-01c932: #01C932;
    --unnamed-color-ff0000: #FF0000;
    --unnamed-color-333366: #333366;
}

body {
    font-family: 'Montserrat', sans-serif;
    background: #6565E2 0% 0% no-repeat padding-box;
    overflow-x: hidden;
    padding: 0;
    line-height: 0;
    font-size: 18px;
    line-height: 22px;
    /* position: relative; */
}

* {
    text-align: center;
    margin: 0;
    padding: 0;
    color: var(--unnamed-color-333366);

    /* font-weight: 600; */
}

/*utilities*/
.position-absolute {
    position: absolute;
}

.d-flex {
    display: flex;
    gap: 11px;
}

.align-items-center {
    align-items: center;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-round {
    justify-content: space-around;
}

.justify-content-left {
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0;
}

.divider {
    border-bottom: 1px solid #707070;
}

.d-none {
    display: none;
}

input, select, label.container {
    height: 60px;
    border: 1px solid var(--unnamed-color-333366);
    border-radius: 10px;
    margin: 12px 0;
    width: 100%;
    text-align: left;
    /* text-indent: 18px; */
    outline: none;
    background-color: white;
    padding: 0 12px;
    display: block;
    box-sizing: border-box;
    font-weight: 400 !important;
}

button {
    background-color: var(--unnamed-color-333366);
    color: white;
    margin-bottom: 18px;
    width: 100%;
    height: 60px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    font-weight: 600;
}

.text-center {
    text-align: center !important;
}

.text-big {
    font-size: 24px;
    line-height: 36px;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.clearfix {
    clear: both;
}

/*end utilities*/

main {
    width: 428px;
    margin: 0 auto;
}

[id^=circle] {
    width: 336px;
    height: 336px;
    background-color: var(--unnamed-color-333366);
    position: absolute;
    border-radius: 50%;
    z-index: -1;
}

#circle1 {
    top: 0;
}

#circle2 {
    top: 35px;
}

#circle3 {
    bottom: 35px;
}

#circle4 {
    bottom: 0;
}

#circle1, #circle3 {
    left: -168px;
}

#circle2, #circle4 {
    right: -168px;
}

@media (max-width: 450px) {
    /* body {
        margin: 0 10px;
    } */

    main {
        width: 100%;
    }
}

#formDiv {
    border-radius: 22px;
    background-color: #f4f4f6;
    padding: 33px 17px;
    text-align: center;
    margin-bottom: 33px;
}

#progressBar {
    margin: 35px 0;
    height: 20px;
    border-radius: 10px;
    background-color: white;
    position: relative;
}

#red {
    position: absolute;
    width: 25%;
    background-color: var(--unnamed-color-ff0000);
    height: 100%;
    border-radius: 10px;
    transition: width 0.75s;
}

.text-red {
    color: #ff0000;
}

.text-bold {
    font-weight: bold;
}

label.error {
    color: red;
    font-size: 12px;
    line-height: 18px;
    display: inline-block;
}

input.valid, select.valid, label.container.valid {
    border-color: var(--unnamed-color-01c932);
    /*background: white url(images/input_valid.png) no-repeat scroll 7px 7px;*/
    background-position: right 22px bottom 19px;
    background-size: 22px;
}
input.error {
    margin-bottom: 0;
    border-color: red;
}

select+label.error {
    display: none !important;
}

select.error {
    border-color: red;
}
.text-left {
    text-align: left;
}

.input-group label, input {
    text-align: left;
    font-size: 18px;
}

/*form1*/
#form1 * {
    font-weight: 600;
}

#form1 > p:first-child {

}

#form1 > p:nth-child(2) {
    color: var(--unnamed-color-ff0000);
    font-size: 32px;
    font-weight: 800;
    letter-spacing: 1.6px;
    line-height: 39px;
    margin-bottom: 10px;
}

#form1 > .flex-center-container {
    margin-bottom: 36px;
}

#form1 > .divider {
    margin: 28.5px 0;
}

#form1 .form-label {
    margin: 0;
}

#form2 .legal-text {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 24px;
}

/*form2*/
#form2 * {
    text-align: left;
}

#form2 p:first-child {
    margin-bottom: 34px;
}

.input-group label {
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
}

#form2 .input-group {
    margin-bottom: 24.7px;
}
#form2 input[id$=Select]{
    cursor: pointer;
}
/*form3*/
#form3 * {
    text-align: left;
}

#form3 > p:first-child {
    margin-bottom: 13px;
}

#form3 > p:nth-child(2) {
    margin-bottom: 23px;
    font-weight: 600;
}

#form3 .ad {
    background: #fff;
    border-radius: 10px;
    border: 1px solid #3333660D;
    padding: 16px 16px 20px 16px;
    margin-bottom: 8px;
}

#form3 .ad > div:first-child {
    margin-bottom: 13px;
}

#form3 > .ad > div:first-child img {
    margin-right: 14px;
}

#form3 > .ad > div:first-child p:first-child b {
    font-size: 15px;
    line-height: 19px;
    margin-bottom: 3px;
    display: block;
}

#form3 .ad > div:first-child p:nth-child(2), #form3 .ad > div:first-child p:nth-child(2) b {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 6px;
}

#form3 .ad .dollar-signs > span {
    background-color: #F3AA0F;
    width: 18.6px;
    height: 18.6px;
    border-radius: 50%;
    margin-right: 6px;
    color: white;
    font-size: 13px;
    text-align: center;
    line-height: 18.6px;
}

#form3 .ad .dollar-signs > span:last-child {
    background-color: var(--unnamed-color-707070);
}

.bullets {
    margin: 12px 0;
}

.bullet {
    margin-bottom: 6px;
}

.bullet img {
    float: left;
    margin-right: 7px;
}

.bullet p {
    font-size: 12px;
    line-height: 15px;
    font-weight: 600;
}

.ad > div:last-child {
    gap: 0;
    margin-top: 19px;
}

#form3 .buttons {
    margin-top: 19px;
}

#form3 .buttons button {
    padding: 9px 15px;
    font-size: 21px;
    line-height: 33px;
    font-weight: 500;
    display: inline-block;
    width: 122px;
    border-radius: 52px;
    display: flex;
    align-items: center;
}

#form3 .buttons button span {
    background-color: white;
    width: 33px;
    height: 33px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 15px;
    text-align: center;
}

#form3 .buttons button img {
    display: inline-block;
    vertical-align: -webkit-baseline-middle;
}

#form3 .buttons a button {
    background-color: var(--unnamed-color-01c932);
    margin-right: 24px;
}

#form3 .buttons > button {
    background-color: var(--unnamed-color-707070);
}

#form3 a {
    text-decoration: none;
    color: white;
}

#form3 button[type=submit] {
    margin-top: 18px;
}
button[type=submit]{
    transition: opacity 0.5s, transform 2s;
}
button[type=submit]:disabled{
    opacity: 0.65;
}
button i.fa {
    margin-left: 15px;
    color: white;
    opacity: 0;
    display: none;
    transition: opacity 0.5s;
}

button[type=submit]:disabled i.fa{
    display: inline-block;
    opacity: 1;
}
/*form4*/
#form4 p:first-child {
    margin-bottom: 25px;
}

#form4 .receive-option {
    background: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    margin-bottom: 15px;
    height: 60px;
    align-items: center;
    padding: 0 15px;
    justify-content: space-between;
    cursor: pointer;
}

#form4 .receive-option span {
    font-size: 16px;
    line-height: 19px;
    color: var(--unnamed-color-333366);
    padding: 0 15px;
}

.opacity-0 {
    opacity: 0;
}

/*custom radio*/
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 18px !important;
  line-height: 60px !important;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
 	top: 9px;
	left: 9px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}

#form4 > a {
  text-decoration: none;
}

.skip-button{
    text-decoration: underline;
}

.infoHeader {
    font-size: 20px;
}

.infoBox {
    background: #c8e7c7;
    border: 1px solid #064a01;
    border-radius: 25px;
    width: 250px;
    height: 250px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
}

.bill-refund{
    background-image: url("./assets/images/cashback_green.png");
    background-size: cover;
}

.form1Button {
    font-size: 22px;
    font-weight: bold;
}

.continue-button {
    font-size: 22px;
}

#input-name::placeholder {
    color: #343a40;
}

#input-phone::placeholder {
    color: #343a40;
}